import { redirect } from '@tanstack/react-router'

import { isHasAdminAccessToken } from '@/utils/app'

export default function () {
  if (isHasAdminAccessToken()) {
    throw redirect({
      to: '/admin/dashboard',
      replace: true,
    })
  }
}
