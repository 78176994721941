import { createApi } from '@reduxjs/toolkit/query/react'

import { CREATE_AND_LIST_BANNER } from '@/config/endpoints'
import { BannerListResponse } from '@/types/banner'
import { baseQuery } from '@/utils/api'

const api = createApi({
  reducerPath: 'banner',
  tagTypes: ['Banner'],
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getListBanner: builder.query<BannerListResponse, void>({
      query: () => ({
        url: CREATE_AND_LIST_BANNER,
      }),
    }),
  }),
})

export const { useLazyGetListBannerQuery, util: bannerUtil } = api

export default api
