import { createApi } from '@reduxjs/toolkit/query/react'
import { UserAddressListResponse, UserProfileResponse } from 'user'

import {
  OrderDetailAssumeRequest,
  OrderListAssumeRequest,
  OrderListDraftResponse,
  OrderListResponse,
  OrderResponse,
} from '@/types/order'
import { baseAdminQuery, composeRequest } from '@/utils/api'

const api = createApi({
  reducerPath: 'assume',
  tagTypes: ['Assume', 'AssumeOrder'],
  baseQuery: baseAdminQuery,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getAssumeProfile: builder.query<UserProfileResponse, string>({
      query: (memberId) => ({
        url: `/assume/${memberId}/me`,
      }),
      providesTags: ['Assume'],
    }),
    getAssumeAddressList: builder.query<UserAddressListResponse, string>({
      query: (memberId) => ({
        url: `/assume/${memberId}/address`,
      }),
    }),
    getDraftOrderList: builder.query<OrderListDraftResponse, OrderListAssumeRequest>({
      query: ({ memberId, request }) => ({
        ...composeRequest(request, `/assume/${memberId}/order/draft`),
        method: 'GET',
      }),
      providesTags: ['AssumeOrder'],
    }),
    getOrderList: builder.query<OrderListResponse, OrderListAssumeRequest>({
      query: ({ memberId, request }) => ({
        ...composeRequest(request, `/assume/${memberId}/order`),
        method: 'GET',
      }),
      providesTags: ['AssumeOrder'],
    }),
    getDraftOrderDetail: builder.query<OrderResponse, OrderDetailAssumeRequest>({
      query: ({ memberId, orderNumber }) => ({
        url: `/assume/${memberId}/order/draft/${orderNumber}`,
      }),
    }),
    getOrderDetail: builder.query<OrderResponse, OrderDetailAssumeRequest>({
      query: ({ memberId, orderNumber }) => ({
        url: `/assume/${memberId}/order/${orderNumber}/detail`,
      }),
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useLazyGetAssumeProfileQuery,
  useLazyGetAssumeAddressListQuery,
  useLazyGetDraftOrderListQuery,
  useLazyGetOrderListQuery,
  useLazyGetDraftOrderDetailQuery,
  useLazyGetOrderDetailQuery,
  util: assumeUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
