import { Router, RouterProvider } from '@tanstack/react-router'
import i18next from 'i18next'
import { Settings } from 'luxon'
import { parse, stringify } from 'qs'
import React, { useEffect } from 'react'

import AbilityContext from '@/contexts/AbilityContext'
import { Route as NotFoundRoute } from '@/routes/__error_404'
import { buildAbility } from '@/utils/abilities'
import { useAppSelector } from '@/utils/hooks'

import './styles/index.css'

import { routeTree } from './routeTree.gen'

const router = new Router({
  routeTree,
  defaultPreload: 'intent',
  caseSensitive: true,
  notFoundRoute: NotFoundRoute,
  stringifySearch: (search) => {
    const result = stringify(search, { encode: false })

    return result ? '?' + result : ''
  },
  parseSearch: (search) => parse(search.replace('?', '')),
})

const App = () => {
  const adminPrivileges = useAppSelector((state) => state.adminPrivileges)
  const language = useAppSelector((state) => state.language)
  const userPrivileges = useAppSelector((state) => state.userPrivileges)

  useEffect(() => {
    i18next.changeLanguage(language.lang)
    Settings.defaultLocale = language.lang
  }, [language.lang])

  return (
    <AbilityContext.Provider
      value={buildAbility([...(adminPrivileges.privileges ?? []), ...(userPrivileges.privileges ?? [])])}
    >
      <RouterProvider router={router} />
    </AbilityContext.Provider>
  )
}

export default App
