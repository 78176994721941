import { createApi } from '@reduxjs/toolkit/query/react'

import {
  CHECK_MEMBER,
  CHECK_VOUCHER,
  ORDER,
  ORDER_CANCEL,
  ORDER_DETAIL,
  ORDER_DRAFT,
  ORDER_DRAFT_CANCEL,
  ORDER_DRAFT_DETAIL,
} from '@/config/endpoints'
import { BaseDetailRequest, BaseRequest, BaseResponse } from '@/types/common'
import {
  CancelOrderRequest,
  CheckMemberRequest,
  CheckVoucherRequest,
  CheckVoucherResponse,
  CreateDraftOrderRequest,
  CreateDraftOrderResponse,
  CreateOrderRequest,
  CreateOrderResponse,
  OrderExportResponse,
  OrderFiltersRequest,
  OrderListDraftResponse,
  OrderListRequest,
  OrderListResponse,
  OrderResponse,
} from '@/types/order'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  reducerPath: 'order',
  tagTypes: ['Order'],
  baseQuery: baseQuery,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    exportAllOrder: builder.query<OrderExportResponse, BaseRequest<unknown, OrderFiltersRequest>>({
      queryFn: async (request, api, extraOptions, baseQuery) => {
        const response: OrderExportResponse = {
          success: true,
          code: 'INTERNAL',
          message: 'OK',
          data: {
            order: [],
          },
        }

        try {
          const { data } = await baseQuery({
            ...composeRequest(request, ORDER),
            method: 'GET',
          })

          const orderResponse = data as OrderListResponse

          if (orderResponse && orderResponse.data) {
            response.data.order = orderResponse.data.items
          }
        } catch (e) {
          console.error(e)
        }

        return { data: response }
      },
    }),
    exportAllOrderDraft: builder.query<OrderExportResponse, BaseRequest<unknown, OrderFiltersRequest>>({
      queryFn: async (request, api, extraOptions, baseQuery) => {
        const response: OrderExportResponse = {
          success: true,
          code: 'INTERNAL',
          message: 'OK',
          data: {
            order: [],
          },
        }

        try {
          const { data } = await baseQuery({
            ...composeRequest(request, ORDER_DRAFT),
            method: 'GET',
          })

          const orderResponse = data as OrderListResponse

          if (orderResponse && orderResponse.data) {
            response.data.order = orderResponse.data.items
          }
        } catch (e) {
          console.error(e)
        }

        return { data: response }
      },
    }),
    createDraftOrder: builder.mutation<CreateDraftOrderResponse, CreateDraftOrderRequest>({
      query: (request) => ({
        ...composeRequest(request, ORDER_DRAFT),
        method: 'POST',
      }),
    }),
    updateDraftOrder: builder.mutation<CreateDraftOrderResponse, CreateDraftOrderRequest>({
      query: (request) => ({
        ...composeRequest(request, ORDER_DRAFT),
        method: 'PUT',
      }),
    }),
    checkMember: builder.query<BaseResponse<any>, CheckMemberRequest>({
      query: (request) => ({
        ...composeRequest(request, CHECK_MEMBER),
        method: 'GET',
      }),
    }),
    checkVoucher: builder.mutation<CheckVoucherResponse, CheckVoucherRequest>({
      query: (request) => ({
        ...composeRequest(request, CHECK_VOUCHER),
        method: 'POST',
      }),
    }),
    createOrder: builder.mutation<CreateOrderResponse, CreateOrderRequest>({
      query: (request) => ({
        ...composeRequest(request, ORDER),
        method: 'POST',
      }),
    }),
    getOrderDraft: builder.query<OrderListDraftResponse, OrderListRequest>({
      query: (request) => ({
        ...composeRequest(request, ORDER_DRAFT),
        method: 'GET',
      }),
      providesTags: ['Order'],
    }),
    getOrder: builder.query<OrderListResponse, OrderListRequest>({
      query: (request) => ({
        ...composeRequest(request, ORDER),
        method: 'GET',
      }),
      providesTags: ['Order'],
    }),
    getDetailOrderDraft: builder.query<OrderResponse, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ORDER_DRAFT_DETAIL),
        method: 'GET',
      }),
    }),
    getDetailOrder: builder.query<OrderResponse, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ORDER_DETAIL),
        method: 'GET',
      }),
    }),
    cancelDraftOrder: builder.mutation<BaseResponse<any>, CancelOrderRequest>({
      query: (request) => ({
        ...composeRequest(request, ORDER_DRAFT_CANCEL),
        method: 'PUT',
      }),
      invalidatesTags: ['Order'],
    }),
    cancelOrder: builder.mutation<BaseResponse<any>, CancelOrderRequest>({
      query: (request) => ({
        ...composeRequest(request, ORDER_CANCEL),
        method: 'PUT',
      }),
      invalidatesTags: ['Order'],
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useCreateDraftOrderMutation,
  useUpdateDraftOrderMutation,
  useCreateOrderMutation,
  useLazyCheckMemberQuery,
  useLazyGetOrderDraftQuery,
  useLazyGetOrderQuery,
  useLazyGetDetailOrderDraftQuery,
  useLazyGetDetailOrderQuery,
  useCheckVoucherMutation,
  useCancelDraftOrderMutation,
  useCancelOrderMutation,
  useLazyExportAllOrderQuery,
  useLazyExportAllOrderDraftQuery,
  util: orderUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
