import { Button } from '@nbsdev/naini-react'
import { useNavigate } from '@tanstack/react-router'
import React from 'react'
import { useTranslation } from 'react-i18next'

import BlankLayout from '@/layouts/BlankLayout'

const NotFound = () => {
  const { i18n } = useTranslation()
  const navigate = useNavigate()

  const handleBackToDashboard = () => {
    navigate({ to: '/' })
  }

  return (
    <BlankLayout title='Page Not Found'>
      <main className='flex h-screen w-full flex-col items-center justify-center bg-white'>
        <div className='flex flex-col items-center justify-center space-y-5 px-4 py-8'>
          <img
            className=' lg:h-[250px]'
            src='/assets/images/page-not-found.svg'
          />

          <div className='flex flex-col items-center'>
            <p className='text-xl font-semibold not-italic leading-[30px] text-dark-1'>
              {i18n.t('common:pageNotFound.title')}
            </p>
            <p className='text-sm font-normal not-italic leading-5 text-dark-3'>
              {i18n.t('common:pageNotFound.description')}
            </p>
          </div>

          <Button
            variant='primary'
            label={i18n.t('profile:accountInformation:backToDashboardButtonLabel')}
            className='w-fit border-none bg-primary'
            size='large'
            onClick={handleBackToDashboard}
          />
        </div>
      </main>
    </BlankLayout>
  )
}

export default NotFound
