import { NotFoundRoute } from '@tanstack/react-router'

import NotFound from '@/modules/error/not-found'

import { Route as RootRoute } from './__root'

export const Route = new NotFoundRoute({
  getParentRoute: () => RootRoute,
  component: NotFound,
})
