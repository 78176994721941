import { createApi } from '@reduxjs/toolkit/query/react'
import {
  ListPrivilegeResponse,
  RoleCreateRequest,
  RoleDetailResponse,
  RoleListRequest,
  RoleListResponse,
  RoleUpdateRequest,
} from 'role'

import { PRIVILEGES, ROLE, ROLE_DETAIL } from '@/config/endpoints'
import { BaseDetailRequest } from '@/types/common'
import { baseAdminQuery, composeRequest } from '@/utils/api'

const api = createApi({
  reducerPath: 'role',
  tagTypes: ['role'],
  baseQuery: baseAdminQuery,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getRole: builder.query<RoleListResponse, RoleListRequest>({
      query: (request) => ({
        ...composeRequest(request, ROLE),
        method: 'GET',
      }),
      providesTags: ['role'],
    }),
    getDetailRole: builder.query<RoleDetailResponse, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ROLE_DETAIL),
        method: 'GET',
      }),
    }),
    getPrivilege: builder.query<ListPrivilegeResponse, RoleListRequest>({
      query: (request) => ({
        ...composeRequest(request, PRIVILEGES),
        method: 'GET',
      }),
    }),
    createRole: builder.mutation<RoleDetailResponse, RoleCreateRequest>({
      query: (request) => ({
        ...composeRequest(request, ROLE),
        method: 'POST',
      }),
    }),
    updateRole: builder.mutation<RoleDetailResponse, RoleUpdateRequest>({
      query: (request) => ({
        ...composeRequest(request, ROLE_DETAIL),
        method: 'PUT',
      }),
    }),
    deleteRole: builder.mutation<RoleDetailResponse, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ROLE_DETAIL),
        method: 'DELETE',
      }),
      invalidatesTags: ['role'],
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useLazyGetRoleQuery,
  useLazyGetPrivilegeQuery,
  useLazyGetDetailRoleQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
  util: roleUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
