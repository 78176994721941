import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AdminProfile } from '@/types/admin'

type AdminState = {
  data?: AdminProfile
}

const initialState: AdminState = {}

export const adminSlice = createSlice({
  initialState,
  name: 'adminState',
  reducers: {
    setAdminData: (state, action: PayloadAction<AdminProfile>) => {
      state.data = action.payload
    },
  },
})

export const { setAdminData } = adminSlice.actions

export default adminSlice.reducer
