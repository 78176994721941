import { createApi } from '@reduxjs/toolkit/query/react'

import {
  PRODUCT_GENERATE_CODE,
  PRODUCT_LIST,
  PRODUCT_PACKAGE_LIST,
  PRODUCT_TYPE_LIST,
  PRODUCT_VARIANT_LIST,
} from '@/config/endpoints'
import { BaseDetailRequest } from '@/types/common'
import {
  ProductGenerateCodeRequest,
  ProductGenerateCodeResponse,
  ProductListRequest,
  ProductListResponse,
  ProductPackageListRequest,
  ProductPackageListResponse,
  ProductTypeListResponse,
  ProductVariantListResponse,
} from '@/types/product'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  reducerPath: 'product',
  tagTypes: ['Product'],
  baseQuery: baseQuery,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getProductPackage: builder.query<ProductPackageListResponse, ProductPackageListRequest>({
      query: (request) => ({
        ...composeRequest(request, PRODUCT_PACKAGE_LIST),
        method: 'GET',
      }),
    }),
    getProductTypes: builder.query<ProductTypeListResponse, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, PRODUCT_TYPE_LIST),
        method: 'GET',
      }),
    }),
    getProductVariants: builder.query<ProductVariantListResponse, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, PRODUCT_VARIANT_LIST),
        method: 'GET',
      }),
    }),
    getProducts: builder.mutation<ProductListResponse, ProductListRequest>({
      query: (request) => ({
        ...composeRequest(request, PRODUCT_LIST),
        method: 'POST',
      }),
    }),
    generateProductCode: builder.mutation<ProductGenerateCodeResponse, ProductGenerateCodeRequest>({
      query: (request) => ({
        ...composeRequest(request, PRODUCT_GENERATE_CODE),
        method: 'POST',
      }),
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useLazyGetProductPackageQuery,
  useLazyGetProductTypesQuery,
  useLazyGetProductVariantsQuery,
  useGetProductsMutation,
  useGenerateProductCodeMutation,
  util: productUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
