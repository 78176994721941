import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type LayoutState = {
  page: {
    loading: boolean
  }
  sidebar: {
    open: boolean
    expand: boolean
  }
}

const initialState: LayoutState = {
  page: {
    loading: false,
  },
  sidebar: {
    open: true,
    expand: false,
  },
}

export const layoutSlice = createSlice({
  initialState,
  name: 'layout',
  reducers: {
    setLayoutPagLoading: (state, action: PayloadAction<boolean>) => {
      state.page.loading = action.payload
    },
    setLayoutSidebarOpen: (state, action: PayloadAction<boolean>) => {
      state.sidebar.open = action.payload
    },
    setLayoutSidebarExpand: (state, action: PayloadAction<boolean>) => {
      state.sidebar.expand = action.payload
    },
  },
})

export const { setLayoutPagLoading, setLayoutSidebarOpen, setLayoutSidebarExpand } = layoutSlice.actions

export default layoutSlice.reducer
