import { createApi } from '@reduxjs/toolkit/query/react'
import { EmailDetailRequest, EmailDetailResponse, EmailEditRequest, EmailListRequest, EmailListResponse } from 'email'

import { EMAIL_TEMPLATE } from '@/config/endpoints'
import { baseAdminQuery, composeRequest } from '@/utils/api'

const api = createApi({
  reducerPath: 'emailTemplateAdmin',
  tagTypes: ['EmailTemplate'],
  baseQuery: baseAdminQuery,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getListEmail: builder.query<EmailListResponse, EmailListRequest>({
      query: (request) => ({
        ...composeRequest(request, EMAIL_TEMPLATE),
      }),
      providesTags: ['EmailTemplate'],
    }),
    getDetailEmail: builder.mutation<EmailDetailResponse, EmailDetailRequest>({
      query: (request) => `${EMAIL_TEMPLATE}/${request?.data?.key}`,
    }),
    updateEmail: builder.mutation<EmailDetailResponse, EmailEditRequest>({
      query: (request) => ({
        ...composeRequest(request, `${EMAIL_TEMPLATE}/${String(request?.data?.key)}`),
        method: 'PUT',
      }),
      invalidatesTags: ['EmailTemplate'],
    }),
  }),
})

// Export hooks for usage in functional components
export const { useLazyGetListEmailQuery, useGetDetailEmailMutation, useUpdateEmailMutation, util: faqUtil } = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
