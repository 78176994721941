import { createApi } from '@reduxjs/toolkit/query/react'
import { CancelOrderRequest, OrderListRequest, OrderListResponse, OrderResponse } from 'order'

import { ADMIN_ORDER, ADMIN_ORDER_CANCEL, ADMIN_ORDER_DETAIL } from '@/config/endpoints'
import { BaseDetailRequest, BaseResponse } from '@/types/common'
import { baseAdminQuery, composeRequest } from '@/utils/api'

const api = createApi({
  reducerPath: 'orderAdmin',
  tagTypes: ['OrderAdmin'],
  baseQuery: baseAdminQuery,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getAdminOrder: builder.query<OrderListResponse, OrderListRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_ORDER),
        method: 'GET',
      }),
      providesTags: ['OrderAdmin'],
    }),
    getDetailAdminOrder: builder.mutation<OrderResponse, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_ORDER_DETAIL),
        method: 'GET',
      }),
    }),
    cancelOrderAdmin: builder.mutation<BaseResponse<any>, CancelOrderRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_ORDER_CANCEL),
        method: 'PUT',
      }),
      invalidatesTags: ['OrderAdmin'],
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useLazyGetAdminOrderQuery,
  useGetDetailAdminOrderMutation,
  useCancelOrderAdminMutation,
  util: orderUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
