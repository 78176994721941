import { createApi } from '@reduxjs/toolkit/query/react'
import { CountDetailResponse, ItemDataResponse, SerialGrowthResponse, SummaryDetailResponse } from 'dashboard'

import {
  DASHBOARD_SERIAL_INCOME,
  DASHBOARD_SERIAL_MEMBER,
  DASHBOARD_TOTAL_BONUS,
  DASHBOARD_TOTAL_MEMBER_ACTIVE,
  DASHBOARD_TOTAL_MEMBER_GROWTH,
  DASHBOARD_TOTAL_SUMMARY_INCOME,
} from '@/config/endpoints'
import { baseAdminQuery } from '@/utils/api'

const api = createApi({
  reducerPath: 'dashboard',
  tagTypes: ['dashboard'],
  baseQuery: baseAdminQuery,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getSummaryTotalBonus: builder.query<SummaryDetailResponse, void>({
      query: () => DASHBOARD_TOTAL_BONUS,
    }),
    getCountTotalActiveMember: builder.query<CountDetailResponse, void>({
      query: () => DASHBOARD_TOTAL_MEMBER_ACTIVE,
    }),
    getSummaryTotalIncome: builder.query<SummaryDetailResponse, void>({
      query: () => DASHBOARD_TOTAL_SUMMARY_INCOME,
    }),
    getTotalMemberGrowth: builder.query<CountDetailResponse, void>({
      query: () => DASHBOARD_TOTAL_MEMBER_GROWTH,
    }),
    getSerialIncome: builder.query<ItemDataResponse, void>({
      query: () => DASHBOARD_SERIAL_INCOME,
    }),
    getSerialMember: builder.query<SerialGrowthResponse, void>({
      query: () => DASHBOARD_SERIAL_MEMBER,
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useLazyGetSummaryTotalBonusQuery,
  useLazyGetCountTotalActiveMemberQuery,
  useLazyGetSummaryTotalIncomeQuery,
  useLazyGetTotalMemberGrowthQuery,
  useLazyGetSerialIncomeQuery,
  useLazyGetSerialMemberQuery,
  util: dashboardUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
