export enum AssetType {
  AVATAR = 1,
  EMAIL = 2,
  ASSETS = 3,
}

export enum Region {
  INDONESIA = 1,
  MALAYSIA,
}

export enum TransactionType {
  ACTIVATION = 'activation',
  UPGRADE = 'upgrade',
  REPEAT_ORDER = 'ro',
}

export enum ShippingType {
  UNKNOWN_TYPE = 0,
  DELIVERY = 1,
  PICKUP = 2,
}

export enum TransactionDataType {
  UNKNOWN_TRANSACTION_TYPE = 0,
  REGISTER_BY_RECRUITER = 1,
  REGISTER_BY_REFERRAL = 2,
  ACTIVATION = 3,
  UPGRADE = 4,
  REPEAT_ORDER = 5,
}

export enum TransactionStep {
  UNKNOWN_STEP = 0,
  STEP_ONE = 1,
  STEP_TWO = 2,
  STEP_THREE = 3,
}

export enum TransactionStatus {
  UNKNOWN_TRANSACTION_STATUS = 0,
  NEED_TO_FOLLOW_UP = 1,
  WAITING_FOR_PAYMENT = 2,
  CONFIRMED = 3,
  EXPIRED = 4,
  SUCCESS = 5,
  CANCELED_BY_SYSTEM = 6,
  CANCELED_BY_USER = 7,
  CANCELED_BY_ADMIN = 8,
}

export enum UserStatus {
  ACTIVE = 1,
  INACTIVE = 2,
  PENDING = 3,
  LOCKED = 4,
  DRAFT = 5,
}

export enum FaqStatus {
  ACTIVE = 1,
  INACTIVE = 2,
}

export enum CheckMemberType {
  EMAIL = 'email',
  PHONE_NUMBER = 'noHP',
  IDENTITY_NUMBER = 'noKTP',
}

export enum GenerationMemberType {
  ALL_GENERATION = 1,
  ACTIVATION_GENERATION = 2,
  THIS_MONTH_GENERATION = 3,
}

export enum SmartplanStatus {
  NONACTIVE = 0,
  STARTER = 1,
  REGUlER,
  PLATINUM,
  SILVER,
}

export enum LogActivityEnum {
  UNKNOWN = 0,
  CREATE = 1,
  EDIT,
  DELETE,
}

export enum ErrorCode {
  RESOURCE_NOT_FOUND = 'E_COMM_1',
  USER_NOT_ACTIVE = 'E_AUTH_2',
  ROLE_BEING_USED = 'E_ROLE_3',
  ERROR_FIELD_VALIDATION = 'E_VALD_1',
  FAQ_BEING_USED = 'E_FAQ_1',
  MEMBER_NOT_FOUND = 'E_USER_7',
  MEMBER_MUST_HAVE_RANK = 'E_BONUS_3',
  NOT_ENOUGH_POINT = 'E_BONUS_1',
}

export enum OrderStatusEnum {
  NEED_TO_FOLLOW_UP = 'NEED_TO_FOLLOW_UP',
  CONFIRMED = 'CONFIRMED',
  WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',
  EXPIRED = 'EXPIRED',
  SUCCESS = 'SUCCESS',
  CANCELED_BY_SYSTEM = 'CANCELED_BY_SYSTEM',
  CANCELED_BY_USER = 'CANCELED_BY_USER',
}

export enum Actions {
  Access = 'access',
}

export enum Privileges {
  Unknown = '',
  ListMemberRead = 'LMR',
  ListMemberCreate = 'LMC',
  ListMemberUpdate = 'LMU',
  ListMemberDelete = 'LMD',
  ListMemberExport = 'LME',
  ListMemberImport = 'LMI',
  LinkGenerationRead = 'JGR',
  LinkGenerationCreate = 'JGC',
  LinkGenerationUpdate = 'JGU',
  LinkGenerationDelete = 'JGD',
  LinkGenerationExport = 'JGE',
  LinkGenerationImport = 'JGI',
  BonusRead = 'BOR',
  BonusCreate = 'BOC',
  BonusUpdate = 'BOU',
  BonusDelete = 'BOD',
  BonusExport = 'BOE',
  BonusImport = 'BOI',
  ReportRead = 'LAR',
  ReportCreate = 'LAC',
  ReportUpdate = 'LAU',
  ReportDelete = 'LAD',
  ReportExport = 'LAE',
  ReportImport = 'LAI',
  FaqRead = 'FAR',
  FaqCreate = 'FAC',
  FaqUpdate = 'FAU',
  FaqDelete = 'FAD',
  FaqTopicRead = 'FACR',
  FaqTopicCreate = 'FACC',
  FaqTopicUpdate = 'FACU',
  FaqTopicDelete = 'FACD',
  FaqExport = 'FAE',
  FaqImport = 'FAI',
  PrivacyPolicyRead = 'PPR',
  PrivacyPolicyCreate = 'PPC',
  PrivacyPolicyUpdate = 'PPU',
  PrivacyPolicyDelete = 'PPD',
  PrivacyPolicyExport = 'PPE',
  PrivacyPolicyImport = 'PPI',
  TermsConditionsRead = 'TCR',
  TermsConditionsCreate = 'TCC',
  TermsConditionsUpdate = 'TCU',
  TermsConditionsDelete = 'TCD',
  TermsConditionsExport = 'TCE',
  TermsConditionsImport = 'TCI',
  RoleRead = 'ROR',
  RoleCreate = 'ROC',
  RoleUpdate = 'ROU',
  RoleDelete = 'ROD',
  RoleExport = 'ROE',
  RoleImport = 'ROI',
  UserAdminRead = 'UAR',
  UserAdminCreate = 'UAC',
  UserAdminUpdate = 'UAU',
  UserAdminDelete = 'UAD',
  UserAdminExport = 'UAE',
  UserAdminImport = 'UAI',
  MasterDataRead = 'MDR',
  MasterDataCreate = 'MDC',
  MasterDataUpdate = 'MDU',
  MasterDataDelete = 'MDD',
  MasterDataExport = 'MDE',
  MasterDataImport = 'MDI',
  PromoManagementRead = 'MPR',
  PromoManagementCreate = 'MPC',
  PromoManagementUpdate = 'MPU',
  PromoManagementDelete = 'MPD',
  PromoManagementExport = 'MPE',
  PromoManagementImport = 'MPI',
  EmailConfigurationRead = 'KER',
  EmailConfigurationCreate = 'KEC',
  EmailConfigurationUpdate = 'KEU',
  EmailConfigurationDelete = 'KED',
  EmailConfigurationExport = 'KEE',
  EmailConfigurationImport = 'KEI',
  CurrencyRead = 'PMUR',
  CurrencyCreate = 'PMUC',
  CurrencyUpdate = 'PMUU',
  CurrencyDelete = 'PMUD',
  CurrencyExport = 'PMUE',
  CurrencyImport = 'PMUI',
  LogActivityRead = 'LOR',
  LogActivityCreate = 'LOC',
  LogActivityUpdate = 'LOU',
  LogActivityDelete = 'LOD',
  LogActivityExport = 'LOE',
  LogActivityImport = 'LOI',
  BannerCreate = 'BC',
}

export enum RankEnum {
  NO_RANK = '-',
  WARRIOR = 'W',
  ELITE = 'E',
  MASTER = 'M',
  GRAND_MASTER = 'GM',
  Null = '',
}

export enum PrivilegesMember {
  Unknown = '',
  TransactionNewMember = 'TNM',
  TransactionActivationMember = 'TAM',
  TransactionUpgradeMember = 'TUM',
  TransactionRepeatOrder = 'TRO',
  GenerationLink = 'GRL',
  Bonus = 'BNS',
}

export enum BonusType {
  GENERATION = 1,
  REPEAT_ORDER,
  ROYALTY,
  LIFESTYLE,
  CASH_VOUCHER,
}

export enum BonusStatus {
  PAID = 1,
  UNPAID = 2,
}

export enum CashVoucherStatus {
  UNKNOWN = 0,
  LOCK = 1,
  CLAIMED = 2,
  EXPIRED = 3,
}

export enum Package {
  STARTER = 1,
  REGULAR = 2,
  PLATINUM = 3,
  SILVER = 4,
}

export enum EmailTemplate {
  CREATE_ORDER = 'create-order-template',
  CREATE_ORDER_RO = 'create-order-ro-template',
  CREATE_ORDER_ACTIVATION = 'create-order-activation-template',
  CREATE_ORDER_UPGRADE = 'create-order-upgrade-template',
  FORGOT_PASSWORD_ADMIN = 'forgot-password-admin-id',
  SUCCESS_CREATE_NEW_MEMBER = 'success-create-new-member-template',
  CARD_VIRTUAL_ACCOUNT = 'card-virtual-account',
  PRODUCT_CARD = 'product-card',
  CREATE_ADMIN = 'create-admin-id',
  RESET_PASSWORD_BY_ADMIN = 'reset-password-by-admin-id',
  FORGOT_PASSWORD = 'forgot-password-id',
  CARD_REDIRECT_URL = 'card-redirect-url',
  Null = '',
}
