import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type AdminPrivilegesState = {
  privileges?: string[]
}

const initialState: AdminPrivilegesState = {}

export const adminPrivilegesSlice = createSlice({
  initialState,
  name: 'adminPrivileges',
  reducers: {
    setAdminPrivileges: (state, action: PayloadAction<string[]>) => {
      state.privileges = action.payload
    },
    clearAdminPrivileges: (state) => {
      state.privileges = undefined
    },
  },
})

export const { setAdminPrivileges, clearAdminPrivileges } = adminPrivilegesSlice.actions

export default adminPrivilegesSlice.reducer
