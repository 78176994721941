import adminState from '@/reducers/admin'
import adminPrivileges from '@/reducers/admin-privileges'
import language from '@/reducers/language'
import layout from '@/reducers/layout'
import userState from '@/reducers/user'
import userPrivileges from '@/reducers/user-privileges'

const rootReducers = {
  adminState,
  adminPrivileges,
  language,
  layout,
  userState,
  userPrivileges,
}

export default rootReducers
