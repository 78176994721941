import { FC, ReactNode } from 'react'
import { useDocumentTitle } from 'usehooks-ts'

import { appName } from '@/config/env'

type DashboardProps = {
  title: string
  children: ReactNode
}

const DashboardLayout: FC<DashboardProps> = ({ title, children }) => {
  useDocumentTitle(`${title} - ${appName}`)

  return <main>{children}</main>
}

export default DashboardLayout
