import { createApi } from '@reduxjs/toolkit/query/react'
import { FaqDetailResponse, FaqListRequest, FaqListResponse, FaqTopicDetailRequest } from 'faq'

import { FAQ } from '@/config/endpoints'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  reducerPath: 'faq',
  tagTypes: ['faq'],
  baseQuery: baseQuery,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getListFaq: builder.query<FaqListResponse, FaqListRequest>({
      query: (request) => ({
        ...composeRequest(request, FAQ),
      }),
      providesTags: ['faq'],
    }),
    getDetailFaq: builder.mutation<FaqDetailResponse, FaqTopicDetailRequest>({
      query: (request) => `${FAQ}/${request?.data?.xid}`,
    }),
  }),
})

// Export hooks for usage in functional components
export const { useLazyGetListFaqQuery, useGetDetailFaqMutation, util: faqUtil } = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
