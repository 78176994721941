import { FileRoute, lazyRouteComponent } from '@tanstack/react-router'

import signedAdmin from '@/middlewares/signed-admin'
import validateParams from '@/middlewares/validate-params'

export const Route = new FileRoute('/assume/$assumeId/dashboard/').createRoute({
  component: lazyRouteComponent(() => import('@/modules/dashboard-assume')),
  beforeLoad: ({ location, navigate, params }) => {
    signedAdmin({ location })

    validateParams(params, navigate, `/assume/${params.assumeId}/dashboard`)
  },
})
