import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { UserProfile } from '@/types/user'

type UserState = {
  data?: UserProfile
}

const initialState: UserState = {}

export const userSlice = createSlice({
  initialState,
  name: 'userState',
  reducers: {
    setUserData: (state, action: PayloadAction<UserProfile>) => {
      state.data = action.payload
    },
  },
})

export const { setUserData } = userSlice.actions

export default userSlice.reducer
