import { createApi } from '@reduxjs/toolkit/query/react'
import { FaqTopicListRequest, FaqTopicListResponse } from 'faq'

import { FAQ_TOPIC } from '@/config/endpoints'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  reducerPath: 'faqTopic',
  tagTypes: ['FaqTopic'],
  baseQuery: baseQuery,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getListTopicFaq: builder.query<FaqTopicListResponse, FaqTopicListRequest>({
      query: (request) => ({
        ...composeRequest(request, FAQ_TOPIC),
      }),
      providesTags: ['FaqTopic'],
    }),
  }),
})

// Export hooks for usage in functional components
export const { useLazyGetListTopicFaqQuery, util: faqUtil } = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
