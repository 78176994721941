import { createApi } from '@reduxjs/toolkit/query/react'
import { TermConditionDetailResponse } from 'term-condition'

import { TERM_CONDITION } from '@/config/endpoints'
import { BaseRequest } from '@/types/common'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  reducerPath: 'termCondition',
  tagTypes: ['termCondition'],
  baseQuery: baseQuery,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getDetailTermCondition: builder.query<TermConditionDetailResponse, BaseRequest>({
      query: (request) => ({
        ...composeRequest(request, TERM_CONDITION),
        method: 'GET',
      }),
      providesTags: ['termCondition'],
    }),
  }),
})

// Export hooks for usage in functional components
export const { useLazyGetDetailTermConditionQuery, util: tcUtil } = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
