import { FileRoute, redirect } from '@tanstack/react-router'

import { isHasAdminAccessToken, isHasAppAdminAccessToken } from '@/utils/app'

export const Route = new FileRoute('/admin/').createRoute({
  beforeLoad: ({ location }) => {
    if (!isHasAppAdminAccessToken() || !isHasAdminAccessToken()) {
      throw redirect({
        to: '/admin/auth/login',
        search: {
          redirect: location.href !== '/' ? location.href : undefined,
        },
      })
    }

    if (isHasAdminAccessToken()) {
      throw redirect({
        to: '/admin/dashboard',
      })
    }
  },
})
