import { createApi } from '@reduxjs/toolkit/query/react'
import { LogActivityListRequest, LogActivityListResponse } from 'log-activity'

import { ACTIVITY_HISTORY } from '@/config/endpoints'
import { baseAdminQuery, composeRequest } from '@/utils/api'

const api = createApi({
  reducerPath: 'logActivity',
  tagTypes: ['LogActivity'],
  baseQuery: baseAdminQuery,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getListLogActivity: builder.query<LogActivityListResponse, LogActivityListRequest>({
      query: (request) => ({
        ...composeRequest(request, ACTIVITY_HISTORY),
      }),
      providesTags: ['LogActivity'],
    }),
  }),
})

// Export hooks for usage in functional components
export const { useLazyGetListLogActivityQuery, util: logActivityUtil } = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
