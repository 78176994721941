import Cookies from 'js-cookie'

import {
  ADMIN_ACCESS_TOKEN_KEY_NAME,
  ADMIN_REFRESH_TOKEN_KEY_NAME,
  APP_ACCESS_TOKEN_KEY_NAME,
  APP_ADMIN_ACCESS_TOKEN_KEY_NAME,
  USER_ACCESS_TOKEN_KEY_NAME,
  USER_REFRESH_TOKEN_KEY_NAME,
} from '@/config/token'

const expiredAtToSeconds = (expiredAt: string) => {
  const expire = new Date(0)
  expire.setUTCSeconds(parseInt(expiredAt))

  return expire
}

export const getAppAccessToken = () => Cookies.get(APP_ACCESS_TOKEN_KEY_NAME)
export const getUserAccessToken = () => Cookies.get(USER_ACCESS_TOKEN_KEY_NAME)
export const getUserRefreshToken = () => Cookies.get(USER_REFRESH_TOKEN_KEY_NAME)
export const getAppAdminAccessToken = () => Cookies.get(APP_ADMIN_ACCESS_TOKEN_KEY_NAME)
export const getAdminAccessToken = () => Cookies.get(ADMIN_ACCESS_TOKEN_KEY_NAME)
export const getAdminRefreshToken = () => Cookies.get(ADMIN_REFRESH_TOKEN_KEY_NAME)

export const setAppAccessToken = (token: string, expiredAt: string) => {
  Cookies.set(APP_ACCESS_TOKEN_KEY_NAME, token, { expires: expiredAtToSeconds(expiredAt) })
}
export const setUserAccessToken = (token: string, expiredAt: string) => {
  Cookies.set(USER_ACCESS_TOKEN_KEY_NAME, token, { expires: expiredAtToSeconds(expiredAt) })
}
export const setUserRefreshToken = (token: string, expiredAt: string) => {
  Cookies.set(USER_REFRESH_TOKEN_KEY_NAME, token, { expires: expiredAtToSeconds(expiredAt) })
}
export const setAppAdminAccessToken = (token: string, expiredAt: string) => {
  Cookies.set(APP_ADMIN_ACCESS_TOKEN_KEY_NAME, token, { expires: expiredAtToSeconds(expiredAt) })
}
export const setAdminAccessToken = (token: string, expiredAt: string) => {
  Cookies.set(ADMIN_ACCESS_TOKEN_KEY_NAME, token, { expires: expiredAtToSeconds(expiredAt) })
}
export const setAdminRefreshToken = (token: string, expiredAt: string) => {
  Cookies.set(ADMIN_REFRESH_TOKEN_KEY_NAME, token, { expires: expiredAtToSeconds(expiredAt) })
}

export const isHasAppAccessToken = () => getAppAccessToken() !== undefined
export const isHasUserAccessToken = () => getUserAccessToken() !== undefined
export const isHasUserRefreshToken = () => getUserRefreshToken() !== undefined
export const isHasAppAdminAccessToken = () => getAppAdminAccessToken() !== undefined
export const isHasAdminAccessToken = () => getAdminAccessToken() !== undefined
export const isHasAdminRefreshToken = () => getAdminRefreshToken() !== undefined

export const removeAuthUserCookies = () => {
  Cookies.remove(USER_ACCESS_TOKEN_KEY_NAME)
  Cookies.remove(USER_REFRESH_TOKEN_KEY_NAME)
}
export const removeAuthAdminCookies = () => {
  Cookies.remove(ADMIN_ACCESS_TOKEN_KEY_NAME)
  Cookies.remove(ADMIN_REFRESH_TOKEN_KEY_NAME)
}
