import { createApi } from '@reduxjs/toolkit/query/react'

import {
  AUTH_ANONYMOUS,
  AUTH_CHANGE_PASSWORD,
  AUTH_FORGOT_PASSWORD,
  AUTH_LOGIN_USER,
  AUTH_LOGOUT_USER,
  AUTH_RESET_PASSWORD,
  AUTH_SETUP_PASSWORD,
} from '@/config/endpoints'
import {
  AuthAnonymousSessionRequest,
  AuthAnonymousSessionResponse,
  AuthChangePasswordRequest,
  AuthChangePasswordResponse,
  AuthForgotPasswordRequest,
  AuthForgotPasswordResponse,
  AuthLoginRequest,
  AuthLoginResponse,
  AuthRefreshSessionRequest,
  AuthResetPasswordRequest,
  AuthResetPasswordResponse,
  AuthSetupPasswordRequest,
  AuthSetupPasswordResponse,
} from '@/types/auth'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  reducerPath: 'auth',
  tagTypes: ['Auth'],
  baseQuery: baseQuery,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    anonymous: builder.mutation<AuthAnonymousSessionResponse, AuthAnonymousSessionRequest>({
      query: (request) => ({
        ...composeRequest(request, AUTH_ANONYMOUS),
        method: 'POST',
      }),
    }),
    login: builder.mutation<AuthLoginResponse, AuthLoginRequest>({
      query: (request) => ({
        ...composeRequest(request, AUTH_LOGIN_USER),
        method: 'POST',
      }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        ...composeRequest({}, AUTH_LOGOUT_USER),
        method: 'DELETE',
      }),
    }),
    refreshSession: builder.mutation<AuthLoginResponse, AuthRefreshSessionRequest>({
      query: (request) => ({
        ...composeRequest(request, AUTH_LOGIN_USER),
        method: 'PUT',
      }),
    }),
    forgotPassword: builder.mutation<AuthForgotPasswordResponse, AuthForgotPasswordRequest>({
      query: (request) => ({
        ...composeRequest(request, AUTH_FORGOT_PASSWORD),
        method: 'POST',
      }),
    }),
    resetPassword: builder.mutation<AuthResetPasswordResponse, AuthResetPasswordRequest>({
      query: (request) => ({
        ...composeRequest(request, AUTH_RESET_PASSWORD),
        method: 'PUT',
      }),
    }),
    setupPassword: builder.mutation<AuthSetupPasswordResponse, AuthSetupPasswordRequest>({
      query: (request) => ({
        ...composeRequest(request, AUTH_SETUP_PASSWORD),
        method: 'POST',
      }),
    }),
    changePassword: builder.mutation<AuthChangePasswordResponse, AuthChangePasswordRequest>({
      query: (request) => ({
        ...composeRequest(request, AUTH_CHANGE_PASSWORD),
        method: 'PUT',
      }),
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useAnonymousMutation,
  useLoginMutation,
  useLogoutMutation,
  useRefreshSessionMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useSetupPasswordMutation,
  useChangePasswordMutation,
  util: authUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
