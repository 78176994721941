import { createApi } from '@reduxjs/toolkit/query/react'
import { SponsorCountResponse, SponsorListResponse } from 'sponsor'

import { SPONSOR_NOTICE_COUNT, SPONSOR_NOTICE_LIST } from '@/config/endpoints'
import { baseQuery } from '@/utils/api'

const api = createApi({
  reducerPath: 'sponsor',
  tagTypes: ['Sponsor'],
  baseQuery: baseQuery,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getSponsor: builder.query<SponsorListResponse, void>({
      query: () => ({
        url: SPONSOR_NOTICE_LIST,
      }),
    }),
    getSponsorCount: builder.query<SponsorCountResponse, void>({
      query: () => ({
        url: SPONSOR_NOTICE_COUNT,
      }),
    }),
  }),
})

// Export hooks for usage in functional components
export const { useLazyGetSponsorQuery, useLazyGetSponsorCountQuery, util: sponsorUtil } = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
