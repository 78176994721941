import { createApi } from '@reduxjs/toolkit/query/react'

import {
  BONUS_CASH_VOUCHER,
  BONUS_HEADER_DETAIL,
  BONUS_POINT_LIFESTYLE,
  BONUS_POINT_LIFESTYLE_DETAIL,
  BONUS_RO_GENERATION,
  BONUS_RO_GENERATION_DETAIL,
  BONUS_ROYALTY,
} from '@/config/endpoints'
import {
  BonusCashVoucherDetailResponse,
  BonusGenerationMemberDetailListResponse,
  BonusGenerationMemberDetailResponse,
  BonusGenerationMemberListResponse,
  BonusListDetailRequest,
  BonusListMemberRequest,
  BonusParams,
  BonusPointMemberDetailListResponse,
  BonusPointMemberResponse,
  BonusRoyaltyDetailResponse,
} from '@/types/bonus'
import { BaseRequest } from '@/types/common'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  reducerPath: 'bonus',
  tagTypes: ['Bonus'],
  baseQuery: baseQuery,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getBonusHeader: builder.query<BonusGenerationMemberDetailResponse, BaseRequest<BonusParams>>({
      query: (request) => ({
        ...composeRequest(request, BONUS_HEADER_DETAIL),
        method: 'GET',
      }),
    }),
    getBonusRoGenerationList: builder.query<BonusGenerationMemberListResponse, BonusListMemberRequest>({
      query: (request) => ({
        ...composeRequest(request, BONUS_RO_GENERATION),
        method: 'GET',
      }),
    }),
    getBonusRoGenerationDetail: builder.query<BonusGenerationMemberDetailListResponse, BonusListDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, BONUS_RO_GENERATION_DETAIL),
        method: 'GET',
      }),
    }),
    getBonusRoyaltyList: builder.query<BonusRoyaltyDetailResponse, BonusListMemberRequest>({
      query: (request) => ({
        ...composeRequest(request, BONUS_ROYALTY),
        method: 'GET',
      }),
    }),
    getBonusCashVoucherList: builder.query<BonusCashVoucherDetailResponse, BonusListMemberRequest>({
      query: (request) => ({
        ...composeRequest(request, BONUS_CASH_VOUCHER),
        method: 'GET',
      }),
    }),
    getBonusPointList: builder.query<BonusPointMemberResponse, BonusListMemberRequest>({
      query: (request) => ({
        ...composeRequest(request, BONUS_POINT_LIFESTYLE),
        method: 'GET',
      }),
    }),
    getBonusPointDetailList: builder.query<BonusPointMemberDetailListResponse, BonusListDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, BONUS_POINT_LIFESTYLE_DETAIL),
        method: 'GET',
      }),
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useLazyGetBonusHeaderQuery,
  useLazyGetBonusRoGenerationListQuery,
  useLazyGetBonusRoGenerationDetailQuery,
  useLazyGetBonusRoyaltyListQuery,
  useLazyGetBonusCashVoucherListQuery,
  useLazyGetBonusPointListQuery,
  useLazyGetBonusPointDetailListQuery,
  util: bonusUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
