import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type UserPrivilegesState = {
  privileges?: string[]
}

const initialState: UserPrivilegesState = {}

export const userPrivilegesSlice = createSlice({
  initialState,
  name: 'userPrivileges',
  reducers: {
    setUserPrivileges: (state, action: PayloadAction<string[]>) => {
      state.privileges = action.payload
    },
    clearUserPrivileges: (state) => {
      state.privileges = undefined
    },
  },
})

export const { setUserPrivileges, clearUserPrivileges } = userPrivilegesSlice.actions

export default userPrivilegesSlice.reducer
