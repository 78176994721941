import { FileRoute, lazyRouteComponent } from '@tanstack/react-router'

import validateParams from '@/middlewares/validate-params'

export const Route = new FileRoute('/delivery-payment/$orderNumber').createRoute({
  component: lazyRouteComponent(() => import('@/modules/delivery-payment')),
  beforeLoad: ({ params, navigate }) => {
    validateParams(params, navigate, `/delivery-payment/${params.orderNumber}`)
  },
})
