import { createApi } from '@reduxjs/toolkit/query/react'
import { UpdateUserAvatarRequest, UserMemberCountResponse, UserProfileListResponse } from 'user'

import {
  ADMIN,
  ADMIN_AVATAR,
  ADMIN_ME,
  ADMIN_ROLE_PRIVILEGE,
  ADMIN_USER,
  ADMIN_USER_DETAIL,
  ADMIN_USER_STATUS,
  USER,
  USER_COUNT,
} from '@/config/endpoints'
import {
  AdminCreateRequest,
  AdminDetailRequest,
  AdminDetailResponse,
  AdminListRequest,
  AdminListResponse,
  AdminProfileResponse,
  AdminRolePrivilegeResponse,
  AdminUpdateRequest,
  AdminUpdateStatusRequest,
  UpdateAdminInformationRequest,
  UserCountRequest,
} from '@/types/admin'
import { BaseDetailRequest } from '@/types/common'
import { baseAdminQuery, composeRequest } from '@/utils/api'

const api = createApi({
  reducerPath: 'admin',
  tagTypes: ['Admin'],
  baseQuery: baseAdminQuery,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getAdminProfile: builder.query<AdminProfileResponse, void>({
      query: () => ({
        url: ADMIN_ME,
      }),
      providesTags: ['Admin'],
    }),
    getAdminPrivilege: builder.query<AdminRolePrivilegeResponse, void>({
      query: () => ({
        url: ADMIN_ROLE_PRIVILEGE,
      }),
    }),
    editAdminInformation: builder.mutation<AdminProfileResponse, UpdateAdminInformationRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN),
        method: 'PUT',
      }),
    }),
    getListAdmin: builder.query<AdminListResponse, AdminListRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_USER),
      }),
      providesTags: ['Admin'],
    }),
    createAdmin: builder.mutation<AdminDetailResponse, AdminCreateRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_USER),
        method: 'POST',
      }),
    }),
    updateAdmin: builder.mutation<AdminDetailResponse, AdminUpdateRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_USER),
        method: 'PUT',
      }),
    }),
    updateAdminStatus: builder.mutation<AdminDetailResponse, AdminUpdateStatusRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_USER_STATUS),
        method: 'PUT',
      }),
      invalidatesTags: ['Admin'],
    }),
    deleteAdmin: builder.mutation<AdminDetailResponse, AdminDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_USER_DETAIL),
        method: 'DELETE',
      }),
      invalidatesTags: ['Admin'],
    }),
    getDetailAdmin: builder.mutation<AdminDetailResponse, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_USER_DETAIL),
        method: 'GET',
      }),
    }),
    getListUser: builder.query<UserProfileListResponse, AdminListRequest>({
      query: (request) => ({
        ...composeRequest(request, USER),
        method: 'GET',
      }),
    }),
    getUserCount: builder.query<UserMemberCountResponse, AdminListRequest>({
      query: (request) => ({
        ...composeRequest(request, USER_COUNT),
        method: 'GET',
      }),
    }),
    getUserCountById: builder.query<UserMemberCountResponse, UserCountRequest>({
      query: (request) => ({
        ...composeRequest(request, USER_COUNT),
        method: 'GET',
      }),
    }),
    updateAdminUserAvatar: builder.mutation<void, UpdateUserAvatarRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_AVATAR),
        method: 'PUT',
      }),
      invalidatesTags: ['Admin'],
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useLazyGetAdminProfileQuery,
  useEditAdminInformationMutation,
  useUpdateAdminMutation,
  useGetDetailAdminMutation,
  useCreateAdminMutation,
  useDeleteAdminMutation,
  useLazyGetListAdminQuery,
  useLazyGetListUserQuery,
  useLazyGetUserCountQuery,
  useUpdateAdminStatusMutation,
  useLazyGetAdminPrivilegeQuery,
  useUpdateAdminUserAvatarMutation,
  useLazyGetUserCountByIdQuery,
  util: adminProfileUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
