import { redirect } from '@tanstack/react-router'

import { AUTH_LOGIN_USER } from '@/config/endpoints'
import { apiBaseUrl } from '@/config/env'
import {
  getUserRefreshToken,
  isHasUserAccessToken,
  isHasUserRefreshToken,
  setUserAccessToken,
  setUserRefreshToken,
} from '@/utils/app'

type BeforeLoad = {
  location: {
    href: string
  }
}

export default async function ({ location }: BeforeLoad) {
  if (!isHasUserAccessToken()) {
    if (isHasUserRefreshToken()) {
      await fetch(apiBaseUrl + AUTH_LOGIN_USER, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${getUserRefreshToken()}`,
        },
        body: JSON.stringify({
          device: {
            deviceId: 'local',
            devicePlatformId: 3,
            notificationChannelId: null,
            notificationToken: null,
            clientIp: null,
          },
        }),
      })
        .then((res) => res.json())
        .then(({ data }) => {
          const { accessSession, refreshSession } = data

          if (accessSession && accessSession.token && accessSession.expiredAt) {
            const { token: accessSessionToken, expiredAt: accessSessionExpiredAt } = accessSession
            setUserAccessToken(accessSessionToken, accessSessionExpiredAt)
          }
          if (refreshSession && refreshSession.token && refreshSession.expiredAt) {
            const { token: refreshSessionToken, expiredAt: refreshSessionExpiredAt } = refreshSession
            setUserRefreshToken(refreshSessionToken, refreshSessionExpiredAt)
          }
        })
        .catch((err) => {
          console.error(err)
        })
    } else {
      throw redirect({
        to: '/auth/login',
        replace: true,
        search: {
          redirect: location.href,
        },
      })
    }
  }
}
