import { createApi } from '@reduxjs/toolkit/query/react'
import { UserCountRequest } from 'admin'
import { UserMemberCountResponse } from 'user'

import { USER, USER_COUNT, USER_GENERATION_TREE } from '@/config/endpoints'
import {
  GenerationTreeRequest,
  GenerationTreeResponse,
  UserListRequest,
  UserListResponse,
} from '@/types/generation-tree'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  reducerPath: 'generationTree',
  tagTypes: ['GenerationTree'],
  baseQuery: baseQuery,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getUserList: builder.query<UserListResponse, UserListRequest>({
      query: (request) => ({
        ...composeRequest(request, USER),
        method: 'GET',
      }),
    }),
    getGenerationTree: builder.query<GenerationTreeResponse, GenerationTreeRequest>({
      query: (request) => ({
        ...composeRequest(request, USER_GENERATION_TREE),
        method: 'GET',
      }),
    }),
    getUserCountById: builder.query<UserMemberCountResponse, UserCountRequest>({
      query: (request) => ({
        ...composeRequest(request, USER_COUNT),
        method: 'GET',
      }),
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useLazyGetUserListQuery,
  useLazyGetGenerationTreeQuery,
  useLazyGetUserCountByIdQuery,
  util: generationTreeUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
