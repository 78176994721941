import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type LanguageState = {
  lang: 'id' | 'en'
}

const initialState: LanguageState = {
  lang: 'en',
}

export const languageSlice = createSlice({
  initialState,
  name: 'language',
  reducers: {
    setLang: (state, action: PayloadAction<'id' | 'en'>) => {
      state.lang = action.payload
    },
  },
})

export const { setLang } = languageSlice.actions

export default languageSlice.reducer
