import { FileRoute, redirect } from '@tanstack/react-router'

import { isHasAppAccessToken, isHasUserAccessToken } from '@/utils/app'

export const Route = new FileRoute('/').createRoute({
  beforeLoad: ({ location }) => {
    if (!isHasAppAccessToken() || !isHasUserAccessToken()) {
      throw redirect({
        to: '/auth/login',
        search: {
          redirect: location.href !== '/' ? location.href : undefined,
        },
      })
    }

    if (isHasUserAccessToken()) {
      throw redirect({
        to: '/dashboard',
      })
    }
  },
})
