import { createApi } from '@reduxjs/toolkit/query/react'

import { PAYMENT_METHOD_LIST } from '@/config/endpoints'
import { PaymentMethodListResponse } from '@/types/payment-method'
import { baseQuery } from '@/utils/api'

const api = createApi({
  reducerPath: 'paymentMethod',
  tagTypes: ['PaymentMethod'],
  baseQuery: baseQuery,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getPaymentMethodList: builder.query<PaymentMethodListResponse, void>({
      query: () => ({
        url: PAYMENT_METHOD_LIST,
      }),
    }),
  }),
})

// Export hooks for usage in functional components
export const { useLazyGetPaymentMethodListQuery, util: paymentMethodUtil } = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
