// Auth
export const AUTH_ANONYMOUS = '/clients/sessions'

// Auth User
export const AUTH_LOGIN_USER = '/users/sessions'
export const AUTH_LOGOUT_USER = '/users/sessions'
export const AUTH_FORGOT_PASSWORD = '/users/passwords/forgot'
export const AUTH_RESET_PASSWORD = '/users/passwords/reset'
export const AUTH_CHANGE_PASSWORD = '/users/passwords/change'
export const AUTH_SETUP_PASSWORD = '/auth/setup-password'

// Auth Admin
export const AUTH_LOGIN_ADMIN = '/admin/sessions'
export const AUTH_LOGOUT_ADMIN = '/admin/sessions'
export const AUTH_FORGOT_PASSWORD_ADMIN = '/admin/passwords/forgot'
export const AUTH_RESET_PASSWORD_ADMIN = '/admin/passwords/reset'
export const AUTH_RESET_PASSWORD_ADMIN_BY_ADMIN = '/admin/users/:xid/password/reset'
export const AUTH_CHANGE_PASSWORD_ADMIN = '/admin/passwords/change'

// Assets
export const ASSETS_UPLOAD_URL = '/assets/upload-url'

// Profile User
export const USER = '/users'
export const USER_COUNT = '/users/count'
export const USER_ME = '/users/me'
export const USER_ADDRESS = '/users/address'
export const USER_ADDRESS_DETAIL = '/users/address/:xid'
export const USER_AVATAR = '/users/avatar'
export const USER_BANK_ACCOUNT = '/users/bank/account'
export const USER_GENERATION = '/users/generation'
export const USER_GENERATION_TREE = '/users/generation-tree'
export const USER_REFERRAL = 'referral/users'

// Profile Admin
export const ADMIN = '/admin'
export const ADMIN_ME = '/admin/me'
export const ADMIN_AVATAR = '/admin/avatar'
export const ADMIN_ROLE_PRIVILEGE = '/admin/role-privilege'
export const ADMIN_USER = 'admin/users'
export const ADMIN_USER_DETAIL = 'admin/users/:xid'
export const ADMIN_USER_STATUS = 'admin/users/:xid/status'

// Bank List
export const BANK_LIST = '/bank'

// Product
export const PRODUCT_PACKAGE_LIST = '/product/packages'
export const PRODUCT_TYPE_LIST = '/product/:xid/types'
export const PRODUCT_VARIANT_LIST = '/product/:xid/variants'
export const PRODUCT_LIST = '/product/products'
export const PRODUCT_GENERATE_CODE = '/product/generate'

// Payment Method
export const PAYMENT_METHOD_LIST = '/users/payment-methods'

// Location
export const PROVINCE_LIST = '/location/provinces'
export const CITY_LIST = '/location/cities'
export const DISTRICT_LIST = '/location/districts'
export const SUB_DISTRICT_LIST = '/location/sub-districts'
export const WAREHOUSE_PICKUP_LIST = '/location/pickup/warehouses'
export const WAREHOUSE_LIST = '/location/warehouse'
export const COURIER_LIST = '/location/delivery/courier'

// Order
export const ORDER = '/order'
export const ORDER_DETAIL = '/order/:xid/detail'
export const ORDER_CANCEL = '/order/cancel'
export const ORDER_DRAFT = '/order/draft'
export const ORDER_DRAFT_DETAIL = '/order/draft/:xid'
export const ORDER_DRAFT_CANCEL = '/order/draft/cancel'
export const CHECK_MEMBER = '/member/check'
export const CHECK_VOUCHER = '/product/voucher/check'
export const ADMIN_ORDER = '/admin/order'
export const ADMIN_ORDER_DETAIL = '/admin/order/:xid/detail'
export const ADMIN_ORDER_CANCEL = '/admin/order/cancel'

// Sponsor
export const SPONSOR_NOTICE_LIST = '/sponsor/notice'
export const SPONSOR_NOTICE_COUNT = '/sponsor/notice/count'

// Role & Privileges
export const ROLE = '/admin/roles'
export const ROLE_DETAIL = '/admin/roles/:xid'
export const PRIVILEGES = '/admin/privileges'

// FAQ Topic
export const FAQ_TOPIC = '/faq-category'

// FAQ
export const FAQ = '/faq'

// Term & Condition
export const TERM_CONDITION = '/term-and-condition'

// Privacy Policy
export const PRIVACY_POLICY = '/privacy-policy'

// ACTIVITY History
export const ACTIVITY_HISTORY = '/activity-history'

// Currency
export const CURRENCY = '/currency/value'

// Dashboard
export const DASHBOARD_TOTAL_BONUS = '/invoice/summary-bonus'
export const DASHBOARD_TOTAL_MEMBER_ACTIVE = '/member/total-active-member'
export const DASHBOARD_TOTAL_MEMBER_GROWTH = '/member/join-member'
export const DASHBOARD_TOTAL_SUMMARY_INCOME = '/invoice/summary-income'
export const DASHBOARD_SERIAL_INCOME = '/invoice/serial-income'
export const DASHBOARD_SERIAL_MEMBER = '/member/serial-growth'

// Bonus
export const ADMIN_BONUS_DETAIL = '/admin/bonus/ro-generation/:bonusCode'
export const ADMIN_BONUS_RO_GENERATION = '/admin/bonus/ro-generation'
export const ADMIN_BONUS_RO_GENERATION_MEMBER = '/admin/bonus/ro-generation/:bonusCode'
export const ADMIN_BONUS_RO_GENERATION_MEMBER_LIST = '/admin/bonus/ro-generation/:bonusCode/member'
export const ADMIN_BONUS_RO_GENERATION_MEMBER_DETAIL_LIST = '/admin/bonus/ro-generation/:bonusCode/member/:memberId'

export const ADMIN_BONUS_ROYALTY = '/admin/bonus/royalty'
export const ADMIN_BONUS_ROYALTY_DETAIL = '/admin/bonus/royalty/:bonusCode'

export const ADMIN_BONUS_CASH_VOUCHER = '/admin/bonus/cash-voucher'
export const ADMIN_BONUS_CASH_VOUCHER_DETAIL = '/admin/bonus/cash-voucher/:bonusCode'

export const ADMIN_BONUS_POINT_LIFESTYLE_BATCH = '/admin/bonus/point-lifestyle/batch'
export const ADMIN_BONUS_POINT_LIFESTYLE_BATCH_MEMBER = '/admin/bonus/point-lifestyle/batch/:bonusCode'
export const ADMIN_BONUS_POINT_LIFESTYLE_MEMBER = '/admin/bonus/point-lifestyle/member'
export const ADMIN_BONUS_POINT_LIFESTYLE_EARNED = '/admin/bonus/point-lifestyle/member/:memberId'
export const ADMIN_BONUS_POINT_LIFESTYLE_REDEEM = '/admin/bonus/redeem/:xid'
export const ADMIN_BONUS_REDEEM = '/admin/bonus/redeem'

export const BONUS_HEADER_DETAIL = '/bonus/header/:bonusCode/:memberId'
export const BONUS_RO_GENERATION = '/bonus/ro-generation'
export const BONUS_RO_GENERATION_DETAIL = '/bonus/ro-generation/:bonusCode'
export const BONUS_ROYALTY = '/bonus/royalty'
export const BONUS_CASH_VOUCHER = '/bonus/cash-voucher'
export const BONUS_POINT_LIFESTYLE = '/bonus/point-lifestyle'
export const BONUS_POINT_LIFESTYLE_DETAIL = '/bonus/point-lifestyle/:bonusCode'

// Email Template
export const EMAIL_TEMPLATE = '/admin/email/templates'

// Banner
export const CREATE_AND_LIST_BANNER = '/banner'
