import { createApi } from '@reduxjs/toolkit/query/react'
import {
  FaqTopicChangeStatusRequest,
  FaqTopicCreateRequest,
  FaqTopicDetailRequest,
  FaqTopicDetailResponse,
  FaqTopicEditRequest,
  FaqTopicListRequest,
  FaqTopicListResponse,
} from 'faq'

import { FAQ_TOPIC } from '@/config/endpoints'
import { baseAdminQuery, composeRequest } from '@/utils/api'

const api = createApi({
  reducerPath: 'faqTopicAdmin',
  tagTypes: ['FaqTopic'],
  baseQuery: baseAdminQuery,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getListTopicFaq: builder.query<FaqTopicListResponse, FaqTopicListRequest>({
      query: (request) => ({
        ...composeRequest(request, FAQ_TOPIC),
      }),
      providesTags: ['FaqTopic'],
    }),
    createFaqTopic: builder.mutation<FaqTopicDetailResponse, FaqTopicCreateRequest>({
      query: (request) => ({
        ...composeRequest(request, FAQ_TOPIC),
        method: 'POST',
      }),
      invalidatesTags: ['FaqTopic'],
    }),
    updateFaqTopic: builder.mutation<FaqTopicDetailResponse, FaqTopicEditRequest>({
      query: (request) => ({
        ...composeRequest(request, `${FAQ_TOPIC}/${String(request?.data?.xid)}`),
        method: 'PUT',
      }),
      invalidatesTags: ['FaqTopic'],
    }),
    changeStatusFaqTopic: builder.mutation<FaqTopicDetailResponse, FaqTopicChangeStatusRequest>({
      query: (request) => ({
        ...composeRequest(request, `${FAQ_TOPIC}/${String(request?.data?.xid)}/status`),
        method: 'PUT',
      }),
      invalidatesTags: ['FaqTopic'],
    }),
    deleteFaqTopic: builder.mutation<FaqTopicDetailResponse, FaqTopicDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, `${FAQ_TOPIC}/${String(request?.data?.xid)}`),
        method: 'DELETE',
      }),
      invalidatesTags: ['FaqTopic'],
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useLazyGetListTopicFaqQuery,
  useCreateFaqTopicMutation,
  useUpdateFaqTopicMutation,
  useChangeStatusFaqTopicMutation,
  useDeleteFaqTopicMutation,
  util: faqUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
