import { createApi } from '@reduxjs/toolkit/query/react'
import { PrivacyPolicyDetailRequest, PrivacyPolicyDetailResponse, PrivacyPolicyUpdateRequest } from 'privacy-policy'

import { PRIVACY_POLICY } from '@/config/endpoints'
import { baseAdminQuery, composeRequest } from '@/utils/api'

const api = createApi({
  reducerPath: 'privacyPolicyAdmin',
  tagTypes: ['privacyPolicy'],
  baseQuery: baseAdminQuery,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    updatePrivacyPolicy: builder.mutation<PrivacyPolicyDetailResponse, PrivacyPolicyUpdateRequest>({
      query: (request) => ({
        ...composeRequest(request, PRIVACY_POLICY),
        method: 'PUT',
      }),
      invalidatesTags: ['privacyPolicy'],
    }),
    getDetailPrivacyPolicy: builder.query<PrivacyPolicyDetailResponse, PrivacyPolicyDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, PRIVACY_POLICY),
        method: 'GET',
      }),
      providesTags: ['privacyPolicy'],
    }),
  }),
})

// Export hooks for usage in functional components
export const { useLazyGetDetailPrivacyPolicyQuery, useUpdatePrivacyPolicyMutation, util: privacyPolicyUtil } = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
