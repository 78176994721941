import { ThemeContext } from '@nbsdev/naini-react'
import { Outlet, useNavigate } from '@tanstack/react-router'
import { FC, MouseEvent, useCallback } from 'react'
import { Toaster } from 'react-hot-toast'

const BaseLayout: FC = () => {
  const navigate = useNavigate()

  const handleNavigate = useCallback((path?: string) => {
    return (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()

      navigate({ to: path }).catch(console.error)
    }
  }, [])

  return (
    <ThemeContext.Provider value={{ onNavigate: handleNavigate }}>
      <Outlet />
      <Toaster
        position='top-center'
        toastOptions={{ duration: 5000 }}
      />
    </ThemeContext.Provider>
  )
}

export default BaseLayout
