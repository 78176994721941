import { createApi } from '@reduxjs/toolkit/query/react'

import {
  USER,
  USER_ADDRESS,
  USER_ADDRESS_DETAIL,
  USER_AVATAR,
  USER_BANK_ACCOUNT,
  USER_GENERATION,
  USER_ME,
  USER_REFERRAL,
} from '@/config/endpoints'
import { BaseDetailRequest, BaseResponse } from '@/types/common'
import {
  CreateUserAddressRequest,
  UpdateBankAccountRequest,
  UpdateUserAddressRequest,
  UpdateUserAvatarRequest,
  UpdateUserInformationRequest,
  UserAddressDetailResponse,
  UserAddressListResponse,
  UserGenerationListRequest,
  UserProfileDataListResponse,
  UserProfileResponse,
  UserReferralRequest,
} from '@/types/user'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  reducerPath: 'user',
  tagTypes: ['User', 'UserAddress'],
  baseQuery: baseQuery,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getUserProfile: builder.query<UserProfileResponse, void>({
      query: () => ({
        url: USER_ME,
      }),
      providesTags: ['User'],
    }),
    updateUserInformation: builder.mutation<UserProfileResponse, UpdateUserInformationRequest>({
      query: (request) => ({
        ...composeRequest(request, USER),
        method: 'PUT',
      }),
    }),
    updateUserAvatar: builder.mutation<BaseResponse, UpdateUserAvatarRequest>({
      query: (request) => ({
        ...composeRequest(request, USER_AVATAR),
        method: 'PUT',
      }),
      invalidatesTags: ['User'],
    }),
    getAddressList: builder.query<UserAddressListResponse, void>({
      query: () => ({
        url: USER_ADDRESS,
      }),
      providesTags: ['UserAddress'],
    }),
    getDetailUserAddress: builder.mutation<UserAddressDetailResponse, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, USER_ADDRESS_DETAIL),
        method: 'GET',
      }),
    }),
    createUserAddress: builder.mutation<UserAddressDetailResponse, CreateUserAddressRequest>({
      query: (request) => ({
        ...composeRequest(request, USER_ADDRESS),
        method: 'POST',
      }),
      invalidatesTags: ['UserAddress'],
    }),
    updateUserAddress: builder.mutation<UserAddressDetailResponse, UpdateUserAddressRequest>({
      query: (request) => ({
        ...composeRequest(request, USER_ADDRESS_DETAIL),
        method: 'PUT',
      }),
      invalidatesTags: ['UserAddress'],
    }),
    deleteUserAddress: builder.mutation<BaseResponse, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, USER_ADDRESS_DETAIL),
        method: 'DELETE',
      }),
      invalidatesTags: ['UserAddress'],
    }),
    updateUserBankAccount: builder.mutation<UserProfileResponse, UpdateBankAccountRequest>({
      query: (request) => ({
        ...composeRequest(request, USER_BANK_ACCOUNT),
        method: 'PUT',
      }),
    }),
    getUserGenerationList: builder.query<UserProfileDataListResponse, UserGenerationListRequest>({
      query: (request) => ({
        ...composeRequest(request, USER_GENERATION),
        method: 'GET',
      }),
      providesTags: ['User'],
    }),
    getUserReferral: builder.query<UserProfileResponse, UserReferralRequest>({
      query: (request) => ({
        ...composeRequest(request, USER_REFERRAL),
        method: 'GET',
      }),
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useLazyGetUserProfileQuery,
  useUpdateUserInformationMutation,
  useUpdateUserAvatarMutation,
  useLazyGetAddressListQuery,
  useGetDetailUserAddressMutation,
  useCreateUserAddressMutation,
  useUpdateUserAddressMutation,
  useDeleteUserAddressMutation,
  useUpdateUserBankAccountMutation,
  useLazyGetUserGenerationListQuery,
  useLazyGetUserReferralQuery,
  util: userProfileUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
