import { createApi } from '@reduxjs/toolkit/query/react'
import { CurrencyDetailResponse, CurrencyEditRequest } from 'currency'

import { CURRENCY } from '@/config/endpoints'
import { baseAdminQuery, composeRequest } from '@/utils/api'

const api = createApi({
  reducerPath: 'currency',
  tagTypes: ['currency'],
  baseQuery: baseAdminQuery,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    updateCurrency: builder.mutation<CurrencyDetailResponse, CurrencyEditRequest>({
      query: (request) => ({
        ...composeRequest(request, CURRENCY),
        method: 'PUT',
      }),
      invalidatesTags: ['currency'],
    }),
    getDetailCurrency: builder.mutation<CurrencyDetailResponse, null>({
      query: () => CURRENCY,
    }),
  }),
})

// Export hooks for usage in functional components
export const { useUpdateCurrencyMutation, useGetDetailCurrencyMutation, util: currencyUtil } = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
