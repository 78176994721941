import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from './en'
import id from './id'

i18next
  .use(initReactI18next)
  .init({
    lng: 'en',
    supportedLngs: ['en', 'id'],
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: { en, id },
    defaultNS: 'common',
  })
  .catch(console.error)

export default i18next
