import { createApi } from '@reduxjs/toolkit/query/react'

import {
  AUTH_ANONYMOUS,
  AUTH_CHANGE_PASSWORD_ADMIN,
  AUTH_FORGOT_PASSWORD_ADMIN,
  AUTH_LOGIN_ADMIN,
  AUTH_LOGOUT_ADMIN,
  AUTH_RESET_PASSWORD_ADMIN,
  AUTH_RESET_PASSWORD_ADMIN_BY_ADMIN,
} from '@/config/endpoints'
import {
  AuthAdminLoginRequest,
  AuthAnonymousSessionRequest,
  AuthAnonymousSessionResponse,
  AuthChangePasswordRequest,
  AuthChangePasswordResponse,
  AuthForgotPasswordAdminRequest,
  AuthForgotPasswordResponse,
  AuthLoginResponse,
  AuthRefreshSessionRequest,
  AuthResetPasswordByAdminRequest,
  AuthResetPasswordRequest,
  AuthResetPasswordResponse,
} from '@/types/auth'
import { baseAdminQuery, composeRequest } from '@/utils/api'

const api = createApi({
  reducerPath: 'authAdmin',
  tagTypes: ['AuthAdmin'],
  baseQuery: baseAdminQuery,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    anonymousAdmin: builder.mutation<AuthAnonymousSessionResponse, AuthAnonymousSessionRequest>({
      query: (request) => ({
        ...composeRequest(request, AUTH_ANONYMOUS),
        method: 'POST',
      }),
    }),
    loginAdmin: builder.mutation<AuthLoginResponse, AuthAdminLoginRequest>({
      query: (request) => ({
        ...composeRequest(request, AUTH_LOGIN_ADMIN),
        method: 'POST',
      }),
    }),
    logoutAdmin: builder.mutation<void, void>({
      query: () => ({
        ...composeRequest({}, AUTH_LOGOUT_ADMIN),
        method: 'DELETE',
      }),
    }),
    refreshSessionAdmin: builder.mutation<AuthLoginResponse, AuthRefreshSessionRequest>({
      query: (request) => ({
        ...composeRequest(request, AUTH_LOGIN_ADMIN),
        method: 'PUT',
      }),
    }),
    changePasswordAdmin: builder.mutation<AuthChangePasswordResponse, AuthChangePasswordRequest>({
      query: (request) => ({
        ...composeRequest(request, AUTH_CHANGE_PASSWORD_ADMIN),
        method: 'PUT',
      }),
    }),
    forgotPasswordAdmin: builder.mutation<AuthForgotPasswordResponse, AuthForgotPasswordAdminRequest>({
      query: (request) => ({
        ...composeRequest(request, AUTH_FORGOT_PASSWORD_ADMIN),
        method: 'POST',
      }),
    }),
    resetPasswordAdmin: builder.mutation<AuthResetPasswordResponse, AuthResetPasswordRequest>({
      query: (request) => ({
        ...composeRequest(request, AUTH_RESET_PASSWORD_ADMIN),
        method: 'PUT',
      }),
    }),
    resetPasswordAdminByAdmin: builder.mutation<AuthResetPasswordResponse, AuthResetPasswordByAdminRequest>({
      query: (request) => ({
        ...composeRequest(request, AUTH_RESET_PASSWORD_ADMIN_BY_ADMIN),
        method: 'PUT',
      }),
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useAnonymousAdminMutation,
  useLoginAdminMutation,
  useLogoutAdminMutation,
  useRefreshSessionAdminMutation,
  useChangePasswordAdminMutation,
  useForgotPasswordAdminMutation,
  useResetPasswordAdminMutation,
  useResetPasswordAdminByAdminMutation,
  util: adminProfileUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
