import { createApi } from '@reduxjs/toolkit/query/react'
import {
  FaqCreateRequest,
  FaqDetailResponse,
  FaqEditRequest,
  FaqListRequest,
  FaqListResponse,
  FaqTopicChangeStatusRequest,
  FaqTopicDetailRequest,
  FaqTopicDetailResponse,
} from 'faq'

import { FAQ } from '@/config/endpoints'
import { baseAdminQuery, composeRequest } from '@/utils/api'

const api = createApi({
  reducerPath: 'faqAdmin',
  tagTypes: ['faq'],
  baseQuery: baseAdminQuery,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getListFaq: builder.query<FaqListResponse, FaqListRequest>({
      query: (request) => ({
        ...composeRequest(request, FAQ),
      }),
      providesTags: ['faq'],
    }),
    createFaq: builder.mutation<FaqDetailResponse, FaqCreateRequest>({
      query: (request) => ({
        ...composeRequest(request, FAQ),
        method: 'POST',
      }),
      invalidatesTags: ['faq'],
    }),
    updateFaq: builder.mutation<FaqDetailResponse, FaqEditRequest>({
      query: (request) => ({
        ...composeRequest(request, `${FAQ}/${String(request?.data?.xid)}`),
        method: 'PUT',
      }),
      invalidatesTags: ['faq'],
    }),
    changeStatusFaq: builder.mutation<FaqDetailResponse, FaqTopicChangeStatusRequest>({
      query: (request) => ({
        ...composeRequest(request, `${FAQ}/${String(request?.data?.xid)}/status`),
        method: 'PUT',
      }),
      invalidatesTags: ['faq'],
    }),
    deleteFaq: builder.mutation<FaqTopicDetailResponse, FaqTopicDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, `${FAQ}/${String(request?.data?.xid)}`),
        method: 'DELETE',
      }),
      invalidatesTags: ['faq'],
    }),
    getDetailFaq: builder.mutation<FaqDetailResponse, FaqTopicDetailRequest>({
      query: (request) => `${FAQ}/${request?.data?.xid}`,
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useLazyGetListFaqQuery,
  useCreateFaqMutation,
  useUpdateFaqMutation,
  useChangeStatusFaqMutation,
  useGetDetailFaqMutation,
  useDeleteFaqMutation,
  util: faqUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
