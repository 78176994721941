import { FileRoute, lazyRouteComponent } from '@tanstack/react-router'

import { AUTH_ANONYMOUS } from '@/config/endpoints'
import { apiBaseUrl, apiClientId, apiClientSecret } from '@/config/env'
import validateParams from '@/middlewares/validate-params'
import { setAppAccessToken } from '@/utils/app'

export const Route = new FileRoute('/referral/$recruiterId/$orderNumber').createRoute({
  component: lazyRouteComponent(() => import('@/modules/self-registration')),
  beforeLoad: ({ params, navigate }) => {
    fetch(apiBaseUrl + AUTH_ANONYMOUS, {
      method: 'POST',
      headers: {
        Authorization: `Basic ${btoa(`${apiClientId}:${apiClientSecret}`)}`,
      },
    })
      .then((res) => res.json())
      .then(({ data }) => {
        const { token, expiredAt } = data.session

        if (token && expiredAt) {
          setAppAccessToken(token, expiredAt)
        }
      })

    validateParams(params, navigate, `/referral/${params.recruiterId}`)
  },
})
