import { createApi } from '@reduxjs/toolkit/query/react'

import { BANK_LIST } from '@/config/endpoints'
import { BankListResponse } from '@/types/bank'
import { baseQuery } from '@/utils/api'

const api = createApi({
  reducerPath: 'bank',
  tagTypes: ['Bank'],
  baseQuery: baseQuery,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getListBank: builder.query<BankListResponse, void>({
      query: () => ({
        url: BANK_LIST,
      }),
    }),
  }),
})

// Export hooks for usage in functional components
export const { useLazyGetListBankQuery, util: bankUtil } = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
