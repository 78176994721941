import { createApi } from '@reduxjs/toolkit/query/react'

import {
  CITY_LIST,
  COURIER_LIST,
  DISTRICT_LIST,
  PROVINCE_LIST,
  SUB_DISTRICT_LIST,
  WAREHOUSE_LIST,
  WAREHOUSE_PICKUP_LIST,
} from '@/config/endpoints'
import {
  CityListRequest,
  CityListResponse,
  DistrictListRequest,
  DistrictListResponse,
  NearWarehouseRequest,
  ProvinceListResponse,
  ShippingListRequest,
  ShippingListResponse,
  SubDistrictListRequest,
  SubDistrictListResponse,
  WarehouseListResponse,
} from '@/types/location'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  reducerPath: 'location',
  tagTypes: ['Location'],
  baseQuery: baseQuery,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getProvinceList: builder.query<ProvinceListResponse, void>({
      query: () => ({
        url: PROVINCE_LIST,
      }),
    }),
    getCityList: builder.query<CityListResponse, CityListRequest>({
      query: (request) => ({
        ...composeRequest(request, CITY_LIST),
        method: 'GET',
      }),
    }),
    getDistrictList: builder.query<DistrictListResponse, DistrictListRequest>({
      query: (request) => ({
        ...composeRequest(request, DISTRICT_LIST),
        method: 'GET',
      }),
    }),
    getSubDistrictList: builder.query<SubDistrictListResponse, SubDistrictListRequest>({
      query: (request) => ({
        ...composeRequest(request, SUB_DISTRICT_LIST),
        method: 'GET',
      }),
    }),
    getWarehouseList: builder.query<WarehouseListResponse, void>({
      query: () => ({
        url: WAREHOUSE_PICKUP_LIST,
      }),
    }),
    getNearWarehouseList: builder.mutation<WarehouseListResponse, NearWarehouseRequest>({
      query: (request) => ({
        ...composeRequest(request, WAREHOUSE_LIST),
        method: 'POST',
      }),
    }),
    getShippingList: builder.mutation<ShippingListResponse, ShippingListRequest>({
      query: (request) => ({
        ...composeRequest(request, COURIER_LIST),
        method: 'POST',
      }),
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useLazyGetProvinceListQuery,
  useLazyGetCityListQuery,
  useLazyGetDistrictListQuery,
  useLazyGetSubDistrictListQuery,
  useLazyGetWarehouseListQuery,
  useGetNearWarehouseListMutation,
  useGetShippingListMutation,
  util: locationUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
