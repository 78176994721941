import { DateTime } from 'luxon'

export const tailingSlash = (url: string) => url.replace(/\/$/, '')

export const formatEpoch = (seconds: number, format: string) => DateTime.fromSeconds(seconds).toFormat(format)
export const formatCurrency = (number: number) => number.toLocaleString('ID')
export const formatDate = (date: string, format: string = 'dd-MM-yyyy・HH:mm'): string | undefined => {
  const dt = DateTime.fromJSDate(new Date(date))
  return dt?.toFormat(format) || undefined
}
export const formatToEpoch = (date: string, format: string) => DateTime.fromFormat(date, format).toSeconds()
export const formatToEndDateEpoch = (date: string, format: string) =>
  Math.floor(DateTime.fromFormat(date, format).endOf('day').toSeconds())
export const formatToJsDate = (date: string, format: string) => DateTime.fromFormat(date, format).toJSDate()
export const formatToEpochWithOffset = (date: string, format: string) =>
  DateTime.fromFormat(date, format).plus({ minutes: DateTime.now().offset }).toSeconds()
export const formatNumber = (number: number, config?: { prefix?: string; suffix?: string }): string => {
  return `${config?.prefix || ''}${number.toLocaleString('en-US', { maximumFractionDigits: 2 }).replace(/,/g, '.')}${
    config?.suffix || ''
  }`
}
