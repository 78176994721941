import { createApi } from '@reduxjs/toolkit/query/react'

import {
  ADMIN_BONUS_CASH_VOUCHER,
  ADMIN_BONUS_CASH_VOUCHER_DETAIL,
  ADMIN_BONUS_DETAIL,
  ADMIN_BONUS_POINT_LIFESTYLE_BATCH,
  ADMIN_BONUS_POINT_LIFESTYLE_BATCH_MEMBER,
  ADMIN_BONUS_POINT_LIFESTYLE_EARNED,
  ADMIN_BONUS_POINT_LIFESTYLE_MEMBER,
  ADMIN_BONUS_POINT_LIFESTYLE_REDEEM,
  ADMIN_BONUS_REDEEM,
  ADMIN_BONUS_RO_GENERATION,
  ADMIN_BONUS_RO_GENERATION_MEMBER,
  ADMIN_BONUS_RO_GENERATION_MEMBER_DETAIL_LIST,
  ADMIN_BONUS_RO_GENERATION_MEMBER_LIST,
  ADMIN_BONUS_ROYALTY,
  ADMIN_BONUS_ROYALTY_DETAIL,
  BONUS_HEADER_DETAIL,
} from '@/config/endpoints'
import {
  BonusCashVoucherDetailRequest,
  BonusCashVoucherDetailResponse,
  BonusCashVoucherListRequest,
  BonusCashVoucherListResponse,
  BonusGenerationDetailRequest,
  BonusGenerationDetailResponse,
  BonusGenerationMemberDetailListResponse,
  BonusGenerationMemberDetailResponse,
  BonusGenerationMemberListResponse,
  BonusListDetailRequest,
  BonusListMemberRequest,
  BonusListRequest,
  BonusListResponse,
  BonusMemberListRequest,
  BonusParams,
  BonusPointMemberResponse,
  BonusRedeemPointDeleteRequest,
  BonusRedeemPointListResponse,
  BonusRedeemPointRequest,
  BonusRedeemPointResponse,
  BonusRoyaltyDetailRequest,
  BonusRoyaltyDetailResponse,
} from '@/types/bonus'
import { BaseRequest } from '@/types/common'
import { baseAdminQuery, composeRequest } from '@/utils/api'

import { assumeUtil } from './assume'

const api = createApi({
  reducerPath: 'bonusAdmin',
  tagTypes: ['BonusAdmin', 'BonusAdminPointRedeem'],
  baseQuery: baseAdminQuery,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getBonusAdminHeader: builder.query<BonusGenerationMemberDetailResponse, BaseRequest<BonusParams>>({
      query: (request) => ({
        ...composeRequest(request, BONUS_HEADER_DETAIL),
        method: 'GET',
      }),
    }),
    getBonusAdminDetail: builder.query<BonusGenerationDetailResponse, BonusGenerationDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_BONUS_DETAIL),
        method: 'GET',
      }),
    }),
    getBonusAdminRoGenerationList: builder.query<BonusListResponse, BonusListRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_BONUS_RO_GENERATION),
        method: 'GET',
      }),
    }),
    getBonusAdminRoGenerationDetail: builder.query<BonusGenerationDetailResponse, BonusGenerationDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_BONUS_RO_GENERATION_MEMBER),
        method: 'GET',
      }),
    }),
    getBonusAdminRoGenerationMemberList: builder.query<BonusGenerationMemberListResponse, BonusListMemberRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_BONUS_RO_GENERATION_MEMBER_LIST),
        method: 'GET',
      }),
    }),
    getBonusAdminRoGenerationMemberDetailList: builder.query<
      BonusGenerationMemberDetailListResponse,
      BonusListDetailRequest
    >({
      query: (request) => ({
        ...composeRequest(request, ADMIN_BONUS_RO_GENERATION_MEMBER_DETAIL_LIST),
        method: 'GET',
      }),
    }),
    getBonusAdminRoyaltyList: builder.query<BonusListResponse, BonusListRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_BONUS_ROYALTY),
        method: 'GET',
      }),
    }),
    getBonusAdminRoyaltyDetail: builder.query<BonusRoyaltyDetailResponse, BonusRoyaltyDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_BONUS_ROYALTY_DETAIL),
        method: 'GET',
      }),
    }),
    getBonusAdminCashVoucherList: builder.query<BonusCashVoucherListResponse, BonusCashVoucherListRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_BONUS_CASH_VOUCHER),
        method: 'GET',
      }),
    }),
    getBonusAdminCashVoucherDetail: builder.query<BonusCashVoucherDetailResponse, BonusCashVoucherDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_BONUS_CASH_VOUCHER_DETAIL),
        method: 'GET',
      }),
    }),
    getBonusAdminPointLifestyleBatchList: builder.query<BonusListResponse, BonusListRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_BONUS_POINT_LIFESTYLE_BATCH),
        method: 'GET',
      }),
    }),
    getBonusAdminPointLifestyleBatchMemberList: builder.query<BonusPointMemberResponse, BonusMemberListRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_BONUS_POINT_LIFESTYLE_BATCH_MEMBER),
        method: 'GET',
      }),
    }),
    getBonusAdminPointLifestyleMemberList: builder.query<BonusPointMemberResponse, BonusMemberListRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_BONUS_POINT_LIFESTYLE_MEMBER),
        method: 'GET',
      }),
    }),
    getBonusAdminPointLifestyleMemberEarnedList: builder.query<
      BonusGenerationMemberDetailListResponse,
      BonusListDetailRequest
    >({
      query: (request) => ({
        ...composeRequest(request, ADMIN_BONUS_POINT_LIFESTYLE_EARNED),
        method: 'GET',
      }),
    }),
    getBonusAdminPointLifestyleMemberRedeemList: builder.query<BonusRedeemPointListResponse, BonusListDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_BONUS_POINT_LIFESTYLE_REDEEM),
        method: 'GET',
      }),
      providesTags: ['BonusAdminPointRedeem'],
    }),
    postRedeemPoint: builder.mutation<BonusRedeemPointResponse, BonusRedeemPointRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_BONUS_REDEEM),
        method: 'POST',
      }),
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(assumeUtil.invalidateTags(['Assume']))
        })
      },
      invalidatesTags: ['BonusAdminPointRedeem'],
    }),
    deleteRedeemPoint: builder.mutation<any, BonusRedeemPointDeleteRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_BONUS_POINT_LIFESTYLE_REDEEM),
        method: 'DELETE',
      }),
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(assumeUtil.invalidateTags(['Assume']))
        })
      },
      invalidatesTags: ['BonusAdminPointRedeem'],
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useLazyGetBonusAdminHeaderQuery,
  useLazyGetBonusAdminDetailQuery,
  useLazyGetBonusAdminRoGenerationListQuery,
  useLazyGetBonusAdminRoGenerationDetailQuery,
  useLazyGetBonusAdminRoGenerationMemberListQuery,
  useLazyGetBonusAdminRoGenerationMemberDetailListQuery,
  useLazyGetBonusAdminRoyaltyListQuery,
  useLazyGetBonusAdminRoyaltyDetailQuery,
  useLazyGetBonusAdminCashVoucherListQuery,
  useLazyGetBonusAdminCashVoucherDetailQuery,
  useLazyGetBonusAdminPointLifestyleBatchListQuery,
  useLazyGetBonusAdminPointLifestyleBatchMemberListQuery,
  useLazyGetBonusAdminPointLifestyleMemberListQuery,
  useLazyGetBonusAdminPointLifestyleMemberEarnedListQuery,
  useLazyGetBonusAdminPointLifestyleMemberRedeemListQuery,
  usePostRedeemPointMutation,
  useDeleteRedeemPointMutation,
  util: bonusAdminUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
