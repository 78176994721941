import { createApi } from '@reduxjs/toolkit/query/react'

import { ASSETS_UPLOAD_URL } from '@/config/endpoints'
import { UploadUrlRequest, UploadUrlResponse } from '@/types/assets'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  reducerPath: 'assets',
  tagTypes: ['Assets'],
  baseQuery: baseQuery,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getUploadUrl: builder.query<UploadUrlResponse, UploadUrlRequest>({
      query: (request) => ({
        ...composeRequest(request, ASSETS_UPLOAD_URL),
        method: 'GET',
      }),
    }),
  }),
})

// Export hooks for usage in functional components
export const { useLazyGetUploadUrlQuery, util: assetsUtil } = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
