import admin from '@/services/admin'
import assets from '@/services/assets'
import assume from '@/services/assume'
import auth from '@/services/auth'
import authAdmin from '@/services/auth-admin'
import bank from '@/services/bank'
import banner from '@/services/banner'
import bonus from '@/services/bonus'
import bonusAdmin from '@/services/bonus-admin'
import currency from '@/services/currency'
import dashboard from '@/services/dashboard'
import emailTemplateAdmin from '@/services/email-template-admin'
import faq from '@/services/faq'
import faqAdmin from '@/services/faq-admin'
import faqTopic from '@/services/faq-topic'
import faqTopicAdmin from '@/services/faq-topic-admin'
import generationTree from '@/services/generation-tree'
import location from '@/services/location'
import logActivity from '@/services/log-activity'
import order from '@/services/order'
import orderAdmin from '@/services/order-admin'
import paymentMethod from '@/services/payment-method'
import privacyPolicy from '@/services/privacy-policy'
import privacyPolicyAdmin from '@/services/privacy-policy-admin'
import product from '@/services/product'
import role from '@/services/role'
import sponsor from '@/services/sponsor'
import termCondition from '@/services/term-condition'
import termConditionAdmin from '@/services/term-condition-admin'
import user from '@/services/user'

const rootServices = {
  reducers: {
    // auth
    [auth.reducerPath]: auth.reducer,
    [authAdmin.reducerPath]: authAdmin.reducer,
    [user.reducerPath]: user.reducer,
    [admin.reducerPath]: admin.reducer,
    [bank.reducerPath]: bank.reducer,
    [product.reducerPath]: product.reducer,
    [location.reducerPath]: location.reducer,
    [order.reducerPath]: order.reducer,
    [role.reducerPath]: role.reducer,
    [paymentMethod.reducerPath]: paymentMethod.reducer,
    [orderAdmin.reducerPath]: orderAdmin.reducer,
    [sponsor.reducerPath]: sponsor.reducer,
    [generationTree.reducerPath]: generationTree.reducer,
    [faqTopic.reducerPath]: faqTopic.reducer,
    [faqTopicAdmin.reducerPath]: faqTopicAdmin.reducer,
    [faq.reducerPath]: faq.reducer,
    [faqAdmin.reducerPath]: faqAdmin.reducer,
    [assets.reducerPath]: assets.reducer,
    [assume.reducerPath]: assume.reducer,
    [termConditionAdmin.reducerPath]: termConditionAdmin.reducer,
    [termCondition.reducerPath]: termCondition.reducer,
    [privacyPolicyAdmin.reducerPath]: privacyPolicyAdmin.reducer,
    [privacyPolicy.reducerPath]: privacyPolicy.reducer,
    [logActivity.reducerPath]: logActivity.reducer,
    [currency.reducerPath]: currency.reducer,
    [dashboard.reducerPath]: dashboard.reducer,
    [bonus.reducerPath]: bonus.reducer,
    [bonusAdmin.reducerPath]: bonusAdmin.reducer,
    [emailTemplateAdmin.reducerPath]: emailTemplateAdmin.reducer,
    [banner.reducerPath]: banner.reducer,
  },
  middlewares: [
    // auth
    auth.middleware,
    authAdmin.middleware,
    user.middleware,
    admin.middleware,
    bank.middleware,
    product.middleware,
    location.middleware,
    order.middleware,
    paymentMethod.middleware,
    role.middleware,
    orderAdmin.middleware,
    sponsor.middleware,
    generationTree.middleware,
    faqTopic.middleware,
    faqTopicAdmin.middleware,
    faq.middleware,
    faqAdmin.middleware,
    assets.middleware,
    termConditionAdmin.middleware,
    termCondition.middleware,
    assume.middleware,
    privacyPolicyAdmin.middleware,
    privacyPolicy.middleware,
    logActivity.middleware,
    currency.middleware,
    dashboard.middleware,
    bonus.middleware,
    bonusAdmin.middleware,
    emailTemplateAdmin.middleware,
    banner.middleware,
  ],
}

export default rootServices
