import { FileRoute, lazyRouteComponent } from '@tanstack/react-router'

import signed from '@/middlewares/signed'
import validateParams from '@/middlewares/validate-params'

export const Route = new FileRoute('/profile/address-list/$addressXID').createRoute({
  component: lazyRouteComponent(() => import('@/modules/profile/address-list/edit')),
  beforeLoad: ({ location, params, navigate }) => {
    signed({ location })

    validateParams(params, navigate, `/profile/address-list/${params.addressXID}`)
  },
})
